<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="" style="z-index: 2 !important"></div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto center-lg">
            <div class="loader">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="-intro-x w-1/2"
                style="width: 500px"
                src="/images/png/public/smouha-logo.svg"
              />
            </div>
            <span class="text-4xl font-bold" style="font-size: 60px"
              >{{ i18n('app.smouha') }}
              <span style="color: #bd212e">{{ i18n('app.sc') }}</span>
            </span>
            <p class="text-4xl font-bold mt-5">
              {{ i18n('app.dashboard') }}
            </p>
            <div class="runprof-logo">
              <a
                href="https://runprof.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/alghoneimy-175eb.appspot.com/o/public%2Frunprof.svg?alt=media&token=0a25f13c-e1ee-432a-8bf3-5cc58056b9dc"
                  class="block mx-auto"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <!-- END: Login Info -->

        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:px-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
            "
          >
            <div class="xl:hidden block flex justify-center">
              <div class="my-auto">
                <img
                  src="/images/png/public/smouha-logo.svg"
                  alt="Smouha SC"
                  class="-intro-x w-1/2 -mt-16"
                  style="width: 200px; padding-top: 86px; padding-bottom: 21px"
                />
              </div>
            </div>
            <div
              class="
                intro-x
                text-gray-700
                dark:text-gray-600
                text-center
                justify-center
                xl:text-white
                flex
              "
            >
              <i18nFlags class="mb-10" @click="changeLoginClass()" />
            </div>
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-white
              "
              :class="isRTL ? ' xl:text-right' : ' xl:text-left'"
            >
              {{ i18n('forgotPassword.title') }}
            </h2>
            <div class="intro-x mt-2 xl:text-white">
              {{ i18n('forgotPassword.desc1') }}<br />
              {{ i18n('forgotPassword.desc2') }}
            </div>
            <div class="intro-x mt-8">
              <input
                type="text"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                "
                :placeholder="i18n('forgotPassword.emailAddress')"
                v-model="form.email"
              />
            </div>

            <div
              v-if="error"
              class="
                alert alert-danger alert-dismissible
                show
                flex
                items-center
                my-2
              "
              role="alert"
              style="z-index: 2 !important"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2 ml-2" />
              {{ i18n(errorData) }}
            </div>

            <div
              class="intro-x login__input mt-5 xl:mt-8 grid grid-cols-12 gap-3"
            >
              <AppButton
                type="button"
                class="
                  btn btn-primary
                  py-3
                  px-4
                  align-top
                  lg:col-span-6
                  col-span-12
                  flex
                  items-center
                "
                :disabled="loading"
                :loading="loading"
                @click="doSubmit()"
              >
                <strong>{{ i18n('forgotPassword.requestResetLink') }}</strong>
                <template v-slot:loading>
                  <app-i18n code="common.loading"></app-i18n>
                  <LoadingIcon
                    icon="three-dots"
                    color="#FFFFFF"
                    style="margin: 0 4px"
                  />
                </template>
              </AppButton>

              <router-link
                to="/auth/login"
                class="
                  btn btn-outline-secondary
                  py-3
                  px-4
                  align-top
                  xl:text-white
                  lg:col-span-6
                  col-span-12
                "
              >
                {{ i18n('forgotPassword.login') }}
              </router-link>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { useStore } from '@/store'
import Toastify from 'toastify-js'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.forgotPassword')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.forgotPassword')
    }
  },
  components: {
    // DarkModeSwitcher
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loadingPasswordResetEmail'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  mounted() {
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      email: ref('')
    })
    const notificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      error,
      errorData,
      form,
      darkMode,
      notificationToggle
    }
  },
  methods: {
    ...mapActions({
      doSendPasswordResetEmail: 'auth/doSendPasswordResetEmail'
    }),
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    changeLoginClass() {
      if (this.isRTL) {
        cash('body').removeClass('login').addClass('login-rtl')
      } else {
        cash('body').removeClass('login-rtl').addClass('login')
      }
    },
    async doSubmit() {
      try {
        const email = this.form.email
        if (email.length == 0) {
          throw this.i18n('forgotPassword.errors.emptyFields')
        }
        if (!this.validateEmail(email)) {
          throw this.i18n('forgotPassword.errors.wrongEmail')
        }
        await this.doSendPasswordResetEmail(email)
      } catch (error) {
        this.error = true
        this.errorData = error
      }
    }
  }
})
</script>

<style lang="scss">
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.runprof-logo {
  bottom: 0;
  position: absolute;
  margin-bottom: 35px;
}
.logo-web {
  margin-left: 50px;
}
[dir='rtl'] .logo-web {
  margin-right: 50px;
}

.loader {
  width: 300px;
  height: 300px;
  // line-height: 150px;

  margin-left: 100px !important;
  margin-bottom: 20px !important;
  margin-right: 100px !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  z-index: 0;
  text-transform: uppercase;
}

.loader:before,
.loader:after {
  opacity: 0;
  box-sizing: border-box;
  content: '\0020';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #bd212e;
  box-shadow: 0 0 50px #bd212e, inset 0 0 50px #bd212e;
}

.loader:after {
  z-index: 1;
  -webkit-animation: gogoloader 2s infinite 1s;
}

.loader:before {
  z-index: 2;
  -webkit-animation: gogoloader 2s infinite;
}

@-webkit-keyframes gogoloader {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
</style>
